.enter-page {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 100vh;
    background-color: #f0f0f0; 
  }
  
  h1 {
    font-size: 2.5rem;
    color: #333; 
    text-align: center;
    margin-bottom: 20px;
  }
  
  button {
    padding: 10px 20px;
    font-size: 1.2rem;
    background-color: #007bff; 
    color: #fff; 
    border: none;
    border-radius: 5px;
    cursor: pointer;
    transition: background-color 0.3s ease;
  }
  
  button:hover {
    background-color: #0056b3; 
  }
  
  /* tabs */
  @media screen and (max-width: 768px) {
    h1 {
      font-size: 2rem; /* Adjust font size for tablets */
    }
    button {
      font-size: 1rem; /* Adjust font size for tablets */
    }
  }

/* phones */
@media screen and (max-width: 480px) {
  h1 {
    font-size: 1.5rem; /* Adjust font size for phones */
    margin-bottom: 10px; /* Adjust margin for phones */
  }
  button {
    font-size: 0.9rem; /* Adjust font size for phones */
    padding: 8px 16px; /* Adjust padding for phones */
  }
}