body {
  margin: 0;
  font-family: Arial, sans-serif;
  background-color: #f0f0f0;
}

.App {
  text-align: center;
}

header {
  background-color: #283593;
  color: white;
  padding: 10px 0;
  text-align: center;
}

nav ul {
  list-style: none;
  padding: 0;
  margin: 0;
  display: flex;
  justify-content: center;
}

nav li {
  margin: 0 20px;
}

nav a {
  text-decoration: none;
  color: white;
  font-weight: bold;
  font-size: 16px;
}

nav a:hover {
  color: #A71930; 
}


.images {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-color: #ffffff;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
  padding: 20px;
  margin-top: 20px;
}

.images {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 20px;
}

.images > div {
  flex: 1;
  padding: 20px;
  text-align: center;
  border: 1px solid #ccc;
  margin: 10px;
}

.images img {
  max-width: 100%;
  height: auto;
}

.images p {
  margin-top: 10px;
  font-size: 16px;
  line-height: 1.5;
}


.image-text {
  text-align: justify;
  color: #333333;
  line-height: 1.6;
}

.footer {
  background-color: #333333;
  color: white;
  padding: 10px;
  text-align: center;
}

@media screen and (max-width: 768px) {
  .images {
    padding: 10px;
  }
}
