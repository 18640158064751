.week-recap {
    text-align: center;
    background-color: #f0f0f0;
    padding: 20px;
    border-radius: 10px;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  }
  
  .week-recap h2 {
    color: #333;
    font-size: 24px;
    margin-bottom: 10px;
  }
  
  .week-recap p {
    color: #666;
    font-size: 16px;
  }

  /* tablet */
  @media screen and (max-width: 768px) {
    .week-recap {
      padding: 15px;
    }
    .week-recap h2 {
        font-size: 20px; 
        margin-bottom: 8px; 
      }
    
      .week-recap p {
        font-size: 14px; 
      }
    }

/* phones */
@media screen and (max-width: 480px) {
    .week-recap {
      padding: 10px; 
    }
  
    .week-recap h2 {
      font-size: 18px; 
      margin-bottom: 6px; 
    }
  
    .week-recap p {
      font-size: 12px; 
    }
  }