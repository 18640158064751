.week-recap {
    max-width: 800px;
    margin: 0 auto;
    padding: 20px;
    background-color: #fff;
    border-radius: 8px;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}

.week-recap h2 {
    font-size: 36px;
    color: #333;
    margin-bottom: 20px;
    text-align: center;
}

.week-recap p {
    font-size: 18px;
    color: #555;
    line-height: 1.6;
    margin-bottom: 20px;
}

.week-recap a {
    color: #007bff;
    text-decoration: underline;
}

.week-recap a:hover {
    color: #0056b3;
}

.week-recap img {
    max-width: 100%;
    height: auto;
    border-radius: 8px;
    margin-bottom: 20px;
}

.week-recap blockquote {
    border-left: 4px solid #007bff;
    margin: 20px 0;
    padding-left: 15px;
    color: #555;
    font-style: italic;
}

.week-recap .author {
    font-size: 14px;
    color: #888;
}

/* Phones */
@media (max-width: 576px) {
    .week-recap h2 {
        font-size: 24px;
    }

    .week-recap p {
        font-size: 16px;
    }
}

/* Tablets */
@media (min-width: 577px) and (max-width: 768px) {
    .week-recap h2 {
        font-size: 28px;
    }

    .week-recap p {
        font-size: 18px;
    }
}
