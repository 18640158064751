.schedule-container {
    font-family: Arial, sans-serif;
    max-width: 800px;
    margin: 0 auto;
    padding: 20px;
    background-color: #f2f2f2;
    border-radius: 10px;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  }
  
  .schedule-container h2 {
    font-size: 28px;
    text-align: center;
    margin-bottom: 20px;
    color: #333;
  }
  
  .schedule-list {
    list-style-type: none;
    padding: 0;
  }
  
  .schedule-item {
    border: 1px solid #ccc;
    border-radius: 5px;
    padding: 15px;
    margin: 15px 0;
    background-color: #fff;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  }
  
  .schedule-item p {
    margin: 10px 0;
    font-size: 16px;
  }
  
  .game-info {
    font-weight: bold;
    color: #333;
  }
  
  .schedule-item:nth-child(odd) {
    background-color: #f9f9f9;
  }
  
  .schedule-item:nth-child(even) {
    background-color: #e6e6e6;
  }
  
  .schedule-item:hover {
    background-color: #ddd;
    transform: scale(1.02);
    transition: background-color 0.3s, transform 0.3s;
  }
  