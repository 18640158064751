
* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
  }
  
  body {
    background-color: #f0f0f0;
    font-family: Arial, sans-serif;
  }
  
  .team-container {
    max-width: 800px;
    margin: 20px auto;
    padding: 20px;
    background-color: #fff;
    border-radius: 10px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
  }
  
  .team-heading {
    font-size: 28px;
    margin-bottom: 15px;
    color: #333;
  }
  
  .player-list {
    list-style: none;
    padding: 0;
    display: flex; 
    flex-wrap: wrap; 
    justify-content: space-between; 
  }
  
  .player-item {
    width: calc(33.33% - 10px); 
    margin-bottom: 20px;
    padding: 15px;
    border: 1px solid #ccc;
    border-radius: 5px;
    background-color: #f9f9f9;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  }
  
  .player-detail {
    font-size: 16px;
    margin-bottom: 10px;
    color: #333;
  }
  
  .player-link {
    color: #007bff;
    text-decoration: none;
    margin-top: 5px;
  }
  
  .player-link:hover {
    text-decoration: underline;
    color: #0056b3;
  }
  
  .player-headshot {
    max-width: 100%; 
    border-radius: 50%;
    margin-bottom: 10px;
  }
  